<template>
  <b-modal
    id="add-edit-productivity"
    ref="my-modal"
    content-class="shadow"
    :title="title"
    no-fade
    no-close-on-backdrop
    :ok-title="$t('Accept')"
    :cancel-title="$t('Cancel')"
  >
    <b-overlay :show="showingProcessing">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-form-group :label="$t('Activity Name')">
            <validation-provider
              #default="{ errors }"
              name="activity name"
              rules="required"
            >
              <b-form-input
                v-model="form.name"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('Activity Name')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group :label="$t('Productive Rating')">
            <validation-provider
              #default="{ errors }"
              name="productivity rating"
              rules="required"
            >
              <b-form-radio-group
                v-model="form.productivity"
                :options="productivityList"
                :stacked="IS_MOBILE()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <template #label>
              <span>{{ $t('Update Since (Optional)') }}</span>
              <feather-icon
                v-b-tooltip.hover="$t('messages.update-productivity-info')"
                icon="InfoIcon"
                class="ml-1 mb-1"
              />
            </template>
            <b-form-datepicker
              id="datepicker-full-width"
              v-model="form.updateSince"
              :placeholder="$t('Select Date')"
              calendar-width="100%"
              class="mb-2"
              :max="TODAY()"
              :date-format-options="COMMON.DATE_PICKER_FORMAT"
            />

          </b-form-group>
        </b-form>
      </validation-observer>
    </b-overlay>
    <template #modal-footer="{ }">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="primary"
            :disabled="isProcessing"
            @click="handleOk"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            <span v-if="form.id">
              {{ $t('Update') }}
            </span>
            <span v-else>
              {{ $t('Save') }}
            </span>
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal, VBModal, BForm, BFormGroup, BFormInput, BSpinner, BButton, BOverlay, BFormRadioGroup, BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import useApollo from '@/plugins/graphql/useApollo'

const defaultForm = () => ({
  id: null,
  name: '',
  productivity: '',
  updateSince: null,
  scope: '',
  timeUsed: '',
})
export default {
  components: {
    BModal,
    ValidationProvider,
    BForm,
    BFormInput,
    BFormGroup,
    ValidationObserver,
    BSpinner,
    BButton,
    BOverlay,
    BFormRadioGroup,
    BFormDatepicker,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      required,
      email,
      title: this.$t('Add Productivity Setting'),
      isProcessing: false,
      form: {
        ...defaultForm(),
      },
      productivityList: [
        { text: this.$t('Productive'), value: 'PRODUCTIVE', color: 'success' },
        { text: this.$t('Neutral'), value: 'NEUTRAL', color: 'primary' },
        { text: this.$t('Unproductive'), value: 'UNPRODUCTIVE', color: 'danger' },
      ],
      showingProcessing: false,
    }
  },

  mounted() {
  },
  methods: {
    handleOk(bvModal) {
      bvModal.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useApollo.project.updateProductivityInfo({
            projectId: this.$store.state.project.selectedProject,
            title: this.form.name,
            productivity: this.form.productivity,
            updateSince: this.form.updateSince,
          }).then(response => {
            this.showSuccessMessage({
              data: {
                message: response.data.updateProductivityInfo.message,
              },
            })
            this.isProcessing = false
            this.form = {
              ...defaultForm(),
            }
          }).finally(() => this.$bvModal.hide('add-edit-productivity'))
        }
      })
    },
  },
}
</script>
